<template>
  <div class="privacy">
    <h1>用户隐私政策</h1>
    <p class="date">更新日期: 2021年3月5日</p>
    <p class="date">生效日期: 2021年3月5日</p>
    <h2>引言</h2>
    <p>人间花絮是一款图片视频分享应用。您可以通过此应用发布图片、视频，表达自我，晒出个人风采。应用在您的授权后，会以呈现在应用的地图上、商店设备上等方式共享您的作品。为了向您提供上述服务，我们会收集您的相关个人信息。我们依据相关法律法规和技术规范，通过此隐私政策帮助您了解我们收集您个人信息的类型及我们如何利用和保护您的个人信息，同时也向您介绍了我们的隐私控制功能，您可以使用这些功能自主地控制您个人信息的使用方式。</p>
    <p>请在使用/继续使用人间花絮应用与服务前，仔细阅读本隐私政策，在确定充分了解并同意后再使用。</p>
    <h2>索引</h2>
    <div id="index">
      <a href="#collection-use">一、我们收集的信息</a>
      <a href="#share-info">二、对外提供</a>
      <a href="#protect-info">三、信息存储和安全</a>
      <a href="#manage-info">四、您如何管理您的个人信息</a>
      <a href="#protect-minors">五、未成年人保护</a>
      <a href="#contact-us">六、如何联系我们</a>
      <a href="#logoff">七、账号注销</a>
      <a href="#others">八、其他</a>
    </div>
    <div id="collection-use">
      <h2>一、我们收集的信息</h2>
      <p>人间花絮是由上海嘤其鸣网络科技有限公司提供的产品及服务，上海嘤其鸣网络科技有限公司注册地址为上海市奉贤区岚丰路1150号。我们会遵循正当、合法、必要的原则，出于本政策所述的以下目的，收集和使用您在使用我们服务过程中主动提供或因使用我们产品和服务而产生的个人信息。</p>
      <div class="sub-list">
        <h3 class="sub-title">1. 第三方SDK</h3>
        <p>1.1 <strong>百度地图SDK：</strong>应用用于地图展示。百度地图SDK（BaiduTrace）获取Android ID，区分不同应用。</p>
      </div>
      <div class="sub-list">
        <h3 class="sub-title">2. 账号注册及登录</h3>
        <p>1.1 当您注册及登录时，如您使用一键登录的功能，基于我们与通信运营商的合作，我们会收集您的<strong>【手机号码和手机运营商信息】</strong>，以便为您提供快捷的登录服务；同时，为帮助您完成注册过程，我们还将收集您的</p>
        <p>1.2 <strong>如果您使用第三方账号进行登录，为了满足网络实名制要求，在您使用发布、评论及其他要求实名认证的功能和服务前，我们将另行收集您的手机号码以完成实名认证。</strong></p>
        <p>1.3 您还可以根据自身需求选择填写或更改性别、出生年月日、教育经历、地区及个人介绍来完善您的信息，但如果您不提供此类信息，不会影响您使用本产品和相关服务。</p>
        <p>1.4 如果您成功注册人间花絮账号，我们会在人间花絮应用程序及网站中显示您的用户名、头像、地区、个人介绍信息。</p>
      </div>
      <div class="sub-list">
        <h3 class="sub-title">3. 向您提供产品和/或服务</h3>
        <p>1.1 为了向您提供我们最核心的信息展示服务，我们会收集<strong>您填写个人资料，您的点赞、关注和评论信息。</strong></p>
        <p>1.2 当您使用发布、编辑内容功能时，我们会请求您授权存储（相册、媒体和其他文件）、相机（摄像头）、麦克风（录音）权限。您如果拒绝授权提供，将无法使用相应功能，但不影响您正常使用人间花絮的其他功能。</p>
        <p>1.3 当您使用发布、评论、点赞功能时，<strong>您发布的文字、照片、视频、评论、点赞信息会存储在我们的服务器中，</strong>因为存储是实现这一功能所必需的。我们会以加密的方式存储，您也可以随时删除这些信息。</p>
        <p>1.4  安全运行与风控验证。为了保障软件与服务的安全运行，防止您的个人信息被非法获取，更准确地预防欺诈和保护账号安全，我们会收集您的设备信息【包括：设备型号、设备名称、SIM卡序列号、设备唯一标识符（包括：IMEI、IMSI、AndroidID、IDFA、OAID）】、地理位置、存储（相册、媒体和其他文件）、浏览器类型和设置、语言设置、传感器、操作系统和应用程序版本、网络设备硬件地址（MAC地址）、登录IP地址、接入网络的方式、网络质量数据、移动网络信息（包括运营商名称）、产品版本号以及软件使用记录信息，如您不同意我们收集前述信息，可能无法完成风控验证。</p>
      </div>
    </div>
    <div id="share-info">
      <h2>二、对外提供</h2>
      <p>对于您的个人信息，我们不会与任何公司、组织和个人进行共享。除非存在以下一种或多种情形：1、事先已得到您的授权；您自行提出的。</p>
    </div>
    <div id="protect-info">
      <h2>三、信息存储和安全</h2>
      <div class="sub-list">
        <h3 class="sub-title">存储：</h3>
        <p>存储地点：我们将从中华人民共和国境内获得的个人信息存放于中华人民共和国境内。以下情形下，我们会确保在履行了法律规定的义务后，向境外实体提供您的个人信息：</p>
        <p>（1）适用的法律有明确规定;</p>
        <p>（2）获得您的明确授权;</p>
        <p>（3）您通过互联网进行跨境交易等个人主动行为。</p>
        <p>针对以上情形，我们会通过合同等形式确保以不低于本政策规定的程度保护您的个人信息。</p>
        <p>存储时间：我们承诺始终按照法律的规定在合理必要期限内在存储您个人信息。超出上述期限后，我们将删除您的个人信息或对您的个人信息进行匿名化处理。如我们停止运营，我们将及时停止收集您个人信息的活动，将停止运营的通知以逐一送达或公告的形式通知您，并对所持有的您的个人信息进行删除或匿名化处理。</p>
      </div>
      <div class="sub-list">
        <h3 class="sub-title">安全：</h3>
        <p>为了保护您的个人信息安全，我们将努力采取各种符合行业标准的安全措施来保护您的个人信息以最大程度降低您的个人信息被毁损、盗用、泄露、非授权访问、使用、披露和更改的风险。我们将积极建立数据分类分级制度、数据安全管理规范、数据安全开发规范来管理规范个人信息的存储和使用，确保未收集与我们提供的服务无关的个人信息。</p>
      </div>
    </div>
    <div id="manage-info">
      <h2>四、您如何管理您的个人信息</h2>
      <p>我们非常重视并尽全力保障您对自己个人信息的相关权利。</p>
      <p>我们通过交互界面的设计，为您提供了可自行访问、获取、更正和删除相关个人信息的渠道，您可通过以下方式进行设置：</p>
      <p>（1） 通过【我-设置-编辑资料】访问、获取、更正自己的个人资料，含头像、昵称、性别、城市、学校、生日、个人介绍</p>
      <p>（2）  通过【我-设置-账号安全】设置、访问、获取、更正账号信息，含手机号、登录密码</p>
    </div>
    <div id="protect-minors">
      <h2>五、未成年人保护</h2>
      <p>我们非常重视对未成年人个人信息的保护。我们会根据相关法律法规的规定，要求不满十四周岁的未成年人在使用人间花絮的服务或向我们提供个人信息前，应当事先取得自己的监护人（比如自己的父母）的授权同意；已满十四周岁未满十八周岁的未成年人，可以事先取得自己的监护人的授权同意或自行授权同意。我们仅在法律法规允许、未成年人的监护人明确同意或者有必要保护未成年人的情况下使用、对外提供或公开披露该信息。</p>
    </div>
    <div id="contact-us">
      <h2>六、如何联系我们</h2>
      <p>当您有其他的投诉、建议、个人信息相关问题时，请通过投诉举报途径与我们联系。您也可以将您的问题发送至343410096@qq.com</p>
      <p>开发者联系电话：19121454659。</p>
    </div>
    <div id="logoff">
      <h2>七、账号注销</h2>
      <p>删除账号是永久性操作。你的主页、帖子及关联信息将被永久性删除。</p>
    </div>
    <div id="others" class="item-last">
      <h2>八、隐私政策的修订</h2>
      <p>因本政策以及我们处理您个人信息事宜引起的任何争议，或者如果您认为我们的个人信息处理行为损害了您的合法权益，您也可向有关政府部门进行反映。</p>
    </div>
  </div>
</template>

<script>
export default {};
</script>

<style lang="scss" scoped>
.privacy {
  max-width: 900px;
  margin: 0 auto;
  h1{text-align:center;font-size:1.6rem;line-height:6.4rem;}
  h2{font-size:1.6rem;line-height:4.4rem;margin-top:2rem;}
  h3{font-size:1.4rem;line-height:4.4rem;}
  p{margin-bottom:0.7rem;}
  .date{text-align:right;font-size:1.2rem;margin-bottom:0;}
  #index a{display:block;text-decoration:none;line-height:2.4rem;color:#035c9a;}
  .sub-list p{padding:0 2em;}
  .item-last {
    padding-bottom: 10rem;
  }
}
</style>